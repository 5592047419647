import React from 'react';
import config from '../../config';

export default function PageFooter() {
  return (
    <footer id="footer">
      <ul className="copyright">
        <li>&copy; {config.authorName}</li>
        <li>
          Powered by{' '}
          <a href="https://www.desiringgod.org/articles/what-is-the-christian-gospel">
            Jesus
          </a>
        </li>
      </ul>
    </footer>
  );
}
