module.exports = {
  siteTitle: 'How can I help you create digital solutions?', // <title>
  manifestName: 'remekoh',
  manifestShortName: 'Reme Ekoh', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#3e2f87',
  manifestThemeColor: '#3e2f87',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  authorName: 'Reme Ekoh',
  heading: 'Developer | Designer \n Consultant',
  // social
  socialLinks: [
    {
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      color: '#0277BD',
      url: 'https://www.linkedin.com/in/remekoh',
    },
    {
      icon: 'fa-github',
      name: 'Github',
      color: '#000000',
      url: 'https://github.com/remekoh',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      color: '#64B5F6',
      url: 'https://twitter.com/remekoh',
    },
  ],
};
